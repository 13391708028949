import React from "react";
import axios from "axios";
import Airtable from "airtable";

export const AirtableContext = React.createContext({});

const getBase = () => {
  var base = new Airtable({
    apiKey:
      "patrs8lVKl22iLD9l.ab87e44f04e6340727a5d8a26fb3fbbb7c7d55e3818d33d9f8616d5ccf215124",
  }).base("appcRatkPgTbTXvtp");
  return base;
};

// Dont forget to add this at the root of your project around your <App />
export default class ProvideAirtableContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      loadingData: false,
      ventes: [],
      venteDetails: [],
      clients: [],
      livreurs: [],
      livreur: null,
      sendBdC: (id) => {
        return new Promise((resolver) => {
          this.updateData("Ventes", id, {
            SendEmail: true,
          }).then(resolver);
        });
      },
      updateLotVente: (venteId, id, qt, price) => {
        return new Promise((resolver) => {
          this.updateData("LotVentes", id, {
            "Quantité (kg)": qt,
            "Prix unitaire": price,
          }).then((result) => {
            if (result) {
              this.generateNewBdC(venteId);
            }
            resolver(result);
          });
        });
      },
      getVenteDetails: (id) => {
        this.setState({ venteDetails: [] });
        this.loadDataFromAirtable(
          "LotVentes",
          (record) =>
            this.setState({
              venteDetails: [
                ...this.state.venteDetails,
                {
                  id: record.id,
                  name: record.get("ProductName"),
                  qt: record.get("Quantité (kg)"),
                  price: record.get("Prix unitaire"),
                },
              ],
            }),
          {
            filterByFormula: 'VenteRecordId = "' + id + '"',
          }
        );
      },
      fetchLivreursVentes: (_) => {
        this.setState({ ventes: [] });
        this.loadDataFromAirtable(
          "Ventes",
          (record) =>
            this.setState({
              ventes: [
                ...this.state.ventes,
                {
                  id: record.id,
                  name: record.get("Société"),
                  date: record.get("Date"),
                  price: record.get("Prix"),
                  url: record.get("Bon de commande"),
                },
              ],
            }),
          {
            filterByFormula: 'livreurRecordId = "' + this.state.livreur + '"',
            sort: [{ field: "Date", direction: "desc" }],
            view: "D'aujourd'hui",
          }
        );
      },
      setLivreur: (id) => this.setState({ livreur: id }),
      loadLivreurs: () => {
        if (this.state.livreurs.length === 0) {
          this.setState({ livreurs: [] });

          this.loadDataFromAirtable(
            "Livreurs",
            (record) =>
              this.setState({
                livreurs: [
                  ...this.state.livreurs,
                  { id: record.id, name: record.get("Nom") },
                ],
              }),
            { view: "Livreur Actifs" }
          );
        }
      },
      reloadClients: () => {
        this.setState({ clients: [] });
        this.loadDataFromAirtable(
          "Clients",
          (record) =>
            this.setState({
              clients: [
                ...this.state.clients,
                { id: record.id, pseudo: record.get("Pseudo") },
              ],
            }),
          { sort: [{ field: "Pseudo", direction: "asc" }] }
        );
      },
      loadClients: () => {
        if (this.state.init == false) {
          this.setState({ init: true, clients: [] });
          this.loadDataFromAirtable(
            "Clients",
            (record) =>
              this.setState({
                clients: [
                  ...this.state.clients,
                  { id: record.id, pseudo: record.get("Pseudo") },
                ],
              }),
            { sort: [{ field: "Pseudo", direction: "asc" }] }
          );
        }
      },
      saveSale: async (client, payment, products) => {
        var data = {
          fields: {
            Client: [client],
            Livreur: [this.state.livreur],
            "Méthode de Paiement": payment,
          },
        };

        return await this.saveData(
          "Ventes",
          data,
          products.map((product) => ({
            ...product,
            price: Number(product.price),
            qt: Number(product.qt),
          }))
        );
      },
      searchBatch: async (lotNbr) => {
        var base = getBase();

        return new Promise((resolver) => {
          base("AchatLineItem")
            .select({
              filterByFormula: '{LotProd} = "' + lotNbr + '"',
              sort: [{ field: "CreatedTime", direction: "desc" }],
            })
            .firstPage(function (err, records) {
              if (err) {
                resolver(null);
                return;
              }
              resolver(records[0]);
            });
        });
      },
      retrieveBatch: async (id) => {
        var base = getBase();

        return new Promise((resolver) => {
          base("AchatLineItem")
            .select({
              filterByFormula: 'barcode = "' + id + '"',
            })
            .firstPage(function (err, records) {
              if (err) {
                resolver(null);
                return;
              }
              resolver(records[0]);
            });
        });
      },
    };
  }

  updateData = async (table, id, fields) => {
    var base = getBase();

    return new Promise((resolver) => {
      base(table).update(
        [
          {
            id: id,
            fields: fields,
          },
        ],
        function (err, records) {
          if (err) {
            resolver(false);
            return;
          }

          resolver(true);
        }
      );
    });
  };

  generateNewBdC = (id) =>
    axios
      .get("https://pdf.benim.be/b/last/" + id)
      .then((_) => true)
      .catch((_) => true);

  saveChunck = async (base, venteId, products) => {
    return new Promise((resolver) => {
      const lotVentesToSave = products.map((product) => ({
        fields: {
          Lot: [product.code],
          Vente: [venteId],
          "Quantité (kg)": product.qt,
          "Prix unitaire": product.price,
        },
      }));

      base("LotVentes").create(lotVentesToSave, (err) => {
        if (err) {
          base("Support").create([
            {
              fields: {
                Title: err.error,
                Message: err.message,
                Extra: JSON.stringify(lotVentesToSave),
              },
            },
          ]);
          resolver(false);
          return;
        }
        resolver(true);
      });
    });
  };

  saveData = async (table, data, products) => {
    return new Promise((resolver) => {
      var base = getBase();

      base(table).create([data], (err, records) => {
        if (err) {
          base("Support").create([
            {
              fields: {
                Title: err.error,
                Message: err.message,
                Extra: JSON.stringify(data),
              },
            },
          ]);
          resolver(false);
          return;
        }

        var promises = [];

        // Save lotVente in chunks because AirTable accepts only 10 rows at a time
        var i,
          chunk,
          chunkSize = 10;
        for (i = 0; i < products.length; i += chunkSize) {
          chunk = products.slice(i, i + chunkSize);
          promises.push(this.saveChunck(base, records[0].id, chunk));
        }

        Promise.all(promises).then((values) => {
          for (let index = 0; index < values.length; index++) {
            const result = values[index];
            if (!result) {
              resolver(false);
              return;
            }
          }

          this.generateNewBdC(records[0].id);
          resolver(true);
        });
      });
    });
  };

  loadDataFromAirtable = (table, callback, filters) => {
    var base = getBase();

    this.setState({ loadingData: true });

    base(table)
      .select(filters)
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            callback(record);
          });
          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          this.setState({ loadingData: false });
        }
      );
  };

  componentDidUpdate() {}

  componentDidMount() {}

  render() {
    return (
      <AirtableContext.Provider value={this.state}>
        {this.props.children}
      </AirtableContext.Provider>
    );
  }
}
